<template>
    <div id="post">
        <div class="post-header">
            <div class="user-logo">
                <!-- <img src="../../assets/images/company2.png" alt="" srcset=""> -->
                <UserLogoComponent
                    width="48" height="48" position="bottom"
                    :user="{ id: post.user_info_id, avatar: post.avatar }"
                />
            </div>
            <div class="user-info">
                <div class="user-name">
                    {{ post.nickname }}
                </div>
                <div class="time-source">
                    <span class="time">
                        发布于{{ post.create_time }}
                    </span>
                    <span class="source">
                        来自碳问
                    </span>
                </div>
            </div>
            <div v-if="token && userInfoId != post.user_info_id" class="follow">
                <el-button v-if="!is_follow" @click="handleFollowChange">
                    <follow />
                    关注
                </el-button>
            </div>
        </div>
        <div class="post-body">
            <div class="title">
                {{ post.title }}
            </div>
            <div class="from">
                来自
                <span>碳问</span>
            </div>
            <div class="content" v-html="post.content"></div>
            <div v-if="parent_article_info && parent_article_info.id" class="parent-post" @click.stop="handleView(parent_article_info.id)">
                <div class="user-info">
                    <!-- <img src="../../assets/images/company2.png"> -->
                    <UserLogoComponent
                        width="26" height="26"
                        :user="parent_user_info"
                    />
                    <div class="info">
                        <span class="user-name">{{ parent_user_info.nickname || '&nbsp;' }}</span>
                        <span class="time">
                            发布于{{ parent_article_info.create_time }}
                        </span>
                    </div>
                </div>
                <div class="title" v-html="parent_article_info.title"></div>
                <div class="content" v-html="parent_article_info.content"></div>
            </div>
        </div>
        <div class="post-footer">
            <div class="post-interact">
                <div class="info">
                    <span class="repeat">
                        <span>{{ article_repeat_count }}</span>
                        &nbsp;
                        转发
                    </span>
                    <span class="dot">·</span>
                    <span class="comment">
                        <span>{{ article_comment_count }}</span>
                        &nbsp;
                        评论
                    </span>
                    <span class="dot">·</span>
                    <span class="like">
                        <span>{{ article_like_count }}</span>
                        &nbsp;
                        赞
                    </span>
                </div>
                <div class="action">
                    <!-- postRepeat -->
                    <div class="repeat" @click="handlePostRepeatOpenDialog">
                        <postRepeat />
                        <span>
                            转发
                        </span>
                    </div>
                    <div :class="['like', is_like ? 'active' : '']" @click="handlePostLikeChange">
                        <like v-if="!is_like" />
                        <likeActive v-else />
                        <span>
                            {{ is_like? '已赞': '赞' }}
                        </span>
                    </div>
                    <div :class="['collection', is_collection ? 'active' : '']" @click="handlePostCollectionChange">
                        <collect v-if="!is_collection" />
                        <collectActive v-else />
                        <span>
                            {{ is_collection? '已收藏': '收藏' }}
                        </span>
                    </div>
                    <div class="wechat"></div>
                    <div class="weibo"></div>
                </div>
            </div>
            <!-- 评论编辑组件 -->
            <CommentEditor ref="comment-editor-ref" @handlePublish="handleCommentPost" />
            <div class="post-comment-list">
                <div class="post-comment-list-header">
                    <div class="count">
                        全部评论
                        （{{ commentList.length }}）
                    </div>
                    <!-- <div class="sort">
                        <span>最近</span>
                        <span>最早</span>
                        <span>赞</span>
                    </div> -->
                </div>
                <div class="post-comment-list-body">
                    <div v-for="item in commentList" :key="item.comment_id" class="post-comment-list-body-item">
                        <div class="list-item-logo-name-time">
                            <!-- <img class="user-logo" src="../../assets/images/userLogo.jpg"> -->
                            <UserLogoComponent
                                width="32" height="32"
                                :user="item.user_info"
                            />
                            <div class="user-name">{{ item.user_info ? item.user_info.nickname : '' }}</div>
                            <div class="time">{{ item.create_time }}</div>
                        </div>
                        <div v-if="item.parent_comment && item.parent_comment.comment_id" class="list-item-parent">
                            <div class="parent-user">
                                @{{ item.parent_comment.user_info_name }}：
                            </div>
                            <div class="parent-content">
                                {{ item.parent_comment.comment_content }}
                            </div>
                        </div>
                        <div class="list-item-content">{{ item.comment_content }}</div>
                        <div class="list-item-action">
                            <div class="like" @click="handleCommentLikeChange(item)">
                                <like v-if="!item.is_like" />
                                <likeActive v-else />
                                <span>
                                    {{ item.is_like ? '已赞' : '赞' }}

                                    <template v-if="item.comment_like_count">
                                        ({{ item.comment_like_count }})
                                    </template>
                                </span>
                            </div>
                            <div class="reply" @click="handleOpenCommentEditor(item)">
                                <reply class="reply" />
                                <span>回复</span>
                            </div>
                        </div>
                        <div v-if="currentCommentId === item.comment_id" class="list-item-comment">
                            <CommentEditor :tinymceId="item.comment_id" ref="comment-comment-editor-ref" type="comment"
                                @handlePublish="handleCommentComment" @handleCancel="handleCancelComment" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PostRepeatDialog :dialogVisible="repeatDialogVisible" @handleClose="handleCloseRepeatDialog"
            @handleSubmit="handleSubmitPostRepeat" />
    </div>
</template>

<script>
import { follow, like, likeActive, collect, collectActive, emote, uploadImage, reply, commentEditorTriangle, postRepeat } from '../../core/icon';
import CommentEditor from '../../components/CommentEditor/index.vue';
import PostRepeatDialog from '../../components/PostRepeatDialog/index.vue';
import UserLogoComponent from '../../components/UserLogo/index.vue';
export default {
    name: 'Post',
    components: {
        follow,
        like,
        likeActive,
        collect,
        collectActive,
        emote,
        uploadImage,
        reply,
        postRepeat,
        CommentEditor,
        commentEditorTriangle,
        PostRepeatDialog,
        UserLogoComponent,
    },
    data() {
        return {
            article_collection_count: 0,
            article_comment_count: 0,
            article_like_count: 0,
            article_repeat_count: 0,
            is_like: false,
            is_collection: false,
            is_follow: false,
            parent_article_info: {},
            parent_user_info: {},
            post: {
                id: '',
                user_info_id: '',
                nickname: '',
            },
            commentList: [],
            currentCommentId: '',

            //文章转发弹窗显示
            repeatDialogVisible: false,

            //登录
            dialogVisible: false,
            loginType: 'account_password',
        }
    },
    activated() {
        console.log('activated, Post');
        if (!this.$route.query.id) return;
        this.getPostInfo();
        this.getAllCommentList();

    },
    computed: {
        token() {
            return this.$store.state.token;
        },
        userInfoId(){
            return this.$store.state.userInfo.id;
        }
    },
    watch: {
        token: function (val) {
            // console.log('watch, token');
            this.getPostInfo();
            this.getAllCommentList();
        }
    },
    methods: {
        getPostInfo() {
            // /api/article/getpostarticleinfo
            this.api.postFormAPISM(
                {
                    post_article_id: this.$route.query.id,
                },
                '/article/getpostarticleinfo'
            ).then(res => {
                // console.log('getPostInfo', res.data);
                let {
                    article_collection_count, article_comment_count, article_like_count, article_repeat_count,
                    article_info, user_info, is_like, is_collection, is_follow, parent_article_info, parent_user_info
                } = res.data.data;
                this.article_collection_count = article_collection_count;
                this.article_comment_count = article_comment_count;
                this.article_like_count = article_like_count;
                this.article_repeat_count = article_repeat_count;
                this.is_like = is_like;
                this.is_collection = is_collection;
                this.is_follow = is_follow;
                this.parent_article_info = parent_article_info;
                this.parent_user_info = parent_user_info;
                this.post = {
                    ...user_info,
                    user_info_id: user_info.id,
                    ...article_info,
                }
                if (this.post.title) {
                    document.title = this.post.title;
                }
            }).catch(error => {
                console.log('error', error);
            })
        },
        // 关注用户 变更
        handleFollowChange(){
            // /api/user/follow
            this.api.postFormAPISM(
                {
                    target_user_id: this.post.user_info_id,
                    is_follow: true,
                },
                '/user/follow'
            ).then(res =>{
                this.getPostInfo();
            }).catch(error => {
                console.log('handleFollowChange, error', error);
            })
        },
        //对文章点赞变更
        handlePostLikeChange() {
            if (!this.token) {
                this.$message({
                    type: 'warning',
                    message: '请先登录'
                });
                return this.handleOpenLogin();
            }
            // /api/article/like
            this.api.postFormAPISM(
                {
                    post_article_id: this.post.id,
                    is_like: !this.is_like,
                },
                '/article/like'
            ).then(res => {
                // console.log('res', res.data);
                this.getPostInfo();
            }).catch(error => {
                console.log('error', error);
            })
        },
        //对文章收藏变更
        handlePostCollectionChange() {
            if (!this.token) {
                this.$message({
                    type: 'warning',
                    message: '请先登录'
                });
                return this.handleOpenLogin();
            }
            // /api/article/collection
            this.api.postFormAPISM(
                {
                    post_article_id: this.post.id,
                    is_collection: !this.is_collection,
                },
                '/article/collection'
            ).then(res => {
                // console.log('res', res.data);
                this.getPostInfo();
            }).catch(error => {
                console.log('error', error);
            })
        },
        //对文章进行评论
        handleCommentPost(text) {
            if (!this.token) {
                this.$message({
                    type: 'warning',
                    message: '请先登录'
                });
                return this.handleOpenLogin();
            }
            console.log('handleCommentPost', text);
            // /api/article/comment
            this.api.postFormAPISM(
                {
                    post_article_id: this.post.id,
                    comment_content: text,
                },
                '/article/comment'
            ).then(res => {
                // console.log('handleCommentPost', res.data);
                let ref = this.$refs['comment-editor-ref'];
                // console.log('ref', ref);
                ref.handleClear && ref.handleClear();
                this.getAllCommentList();
                this.getPostInfo();
            }).catch(error => {
                console.log('handleCommentPost, error', error);
            })
        },
        //对文章转发 打开转发弹窗dialog
        handlePostRepeatOpenDialog() {
            if (!this.token) {
                this.$message({
                    type: 'warning',
                    message: '请先登录'
                });
                return this.handleOpenLogin();
            }
            this.repeatDialogVisible = true;
        },
        // //对文章转发 提交弹窗dialog
        handleSubmitPostRepeat(content) {
            // /api/article/repeat
            this.api.postFormAPISM(
                {
                    parent_post_article_id: this.post.id,
                    content,
                    is_draft: false,
                    post_article_id: null,
                },
                '/article/repeat'
            ).then(res => {
                console.log('handleSubmitPostRepeat', res.data, res.data.hasOwnProperty('code'));
                if (res.status === 200 && typeof res.data === 'object' && !(res.data.hasOwnProperty('code'))) {
                    this.$message({
                        type: 'success',
                        message: '转发成功'
                    });
                    this.handleCloseRepeatDialog();
                    this.getPostInfo();
                } else {
                    this.$message({
                        type: 'error',
                        message: res.data ? res.data.message || '转发失败' : '转发失败',
                    });
                }

            }).catch(error => {
                console.log('error', error);
            })
        },
        handleCloseRepeatDialog() {
            this.repeatDialogVisible = false;
        },

        //对评论点赞变更
        handleCommentLikeChange(item) {
            // console.log('handleCommentLikeChange');
            // Login.methods && Login.methods.handleOpenLogin && Login.methods.handleOpenLogin();
            // return
            if (!this.token) {
                this.$message({
                    type: 'warning',
                    message: '请先登录'
                });
                return this.handleOpenLogin();
            }
            // /api/article/commentlike
            let { is_like, comment_id } = item;
            this.api.postFormAPISM(
                {
                    post_article_comment_id: comment_id,
                    is_like: !is_like
                },
                '/article/commentlike'
            ).then(res => {
                this.getAllCommentList();
            }).catch(error => {
                console.log('error', error);
            })
        },
        //打开对评论的评论 编辑器
        handleOpenCommentEditor(item) {
            if (!this.token) {
                this.$message({
                    type: 'warning',
                    message: '请先登录'
                });
                return this.handleOpenLogin();
            }
            let { comment_id } = item;
            this.currentCommentId = comment_id;
        },
        // 对评论进行评论
        handleCommentComment(text) {
            if (!this.token) {
                this.$message({
                    type: 'warning',
                    message: '请先登录'
                });
                return this.handleOpenLogin();
            }
            console.log('handleCommentComment', text);
            // /api/article/comment
            this.api.postFormAPISM(
                {
                    post_article_id: this.post.id,
                    comment_content: text,
                    parent_comment_id: this.currentCommentId,
                },
                '/article/comment'
            ).then(res => {
                // console.log('handleCommentPost', res.data);
                this.handleCancelComment();
                this.getAllCommentList();
                this.getPostInfo();
            }).catch(error => {
                console.log('error', error);
            })
        },
        // 对评论取消评论
        handleCancelComment() {
            let ref = this.$refs['comment-comment-editor-ref'];
            // console.log('ref', ref);
            ref.handleClear && ref.handleClear();
            this.currentCommentId = '';
        },
        getAllCommentList(item) {
            // /api/article/getcommentlist
            this.api.postFormAPISM(
                {
                    post_article_id: this.$route.query.id,
                },
                '/article/getcommentlist'
            ).then(res => {
                // console.log('getAllCommentList', res.data);
                this.commentList = res.data.data.comment_list;
            }).catch(error => {
                console.log('error', error);
            })
        },
        handleOpenLogin() {
            // this.dialogVisible = true;
            this.$login.show();
        },
        handleCloseLogin() {

            this.dialogVisible = false;
        },
        handleView(id) {
            // this.$router.push(
            //     {
            //         path: '/news',
            //         query: {
            //             page: page + 1,
            //         }
            //     }
            // );
            let text = this.$router.resolve({
                path: "/post",
                query: {
                    id,
                },
            });
            // console.log("text", text.href);
            // 打开一个新的页面
            window.open(text.href, "_blank");
        },
    }
}
</script>
<style lang="less">
@import './index.less';
</style>